<template>
<section class="about">
   <div class="banner">
      <img :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   </div>
   <section class="main_contain">
      <section>
         <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="$t('PC.About.byrxfs')" name="first">
               <div class="tab_1">
                  <figure class="tab1_b1">
                     <img src="./../../assets/zh/images/aboutus_img1.jpg" alt="" />
                     <span>
                        {{ $t("PC.About.vljjpg") }}
                     </span>
                  </figure>
               </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('PC.About.6wik3r')" name="second">
               <div class="tab_2">
                  <div>
                     <p>{{ $t("PC.About.j10i6n") }}</p>
                     <span v-html="$t('PC.About.bq0ufd')"> </span>
                  </div>
                  <div>
                     <p>{{ $t("PC.About.6pjvyv") }}</p>
                     <span>
                        {{ $t("PC.About.bqgqjp") }}
                     </span>
                  </div>
                  <div>
                     <p>{{ $t("PC.About.6x1gqh") }}</p>
                     <span>
                        {{ $t("PC.About.h9xq6c") }}
                     </span>
                  </div>
               </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('PC.About.5cjk9v')" name="third">
               <div class="tab_3">
                  <p>{{ $t("PC.About.3xo7pd") }}</p>
                  <div>
                     <figure>
                        <figcaption>{{ $t("PC.About.ia35og") }}</figcaption>
                        <p>{{ $t("PC.About.bbrgxc") }}</p>
                     </figure>
                     <figure>
                        <figcaption>{{ $t("PC.About.tpb6xv") }}</figcaption>
                        <p>
                           {{ $t("PC.About.owm9kp") }}
                        </p>
                     </figure>
                     <figure>
                        <figcaption>{{ $t("PC.About.dj6fzo") }}</figcaption>
                        <p>{{ $t("PC.About.3obs4n") }}</p>
                     </figure>
                  </div>
               </div>
            </el-tab-pane>

         </el-tabs>
         <div class="xx">
            <figure class="tab1_b2">
               <figcaption>{{ $t("PC.About.cdtm6x") }}</figcaption>
               <div class="b2_box">
                  <article class="center_b"></article>
                  <div class="content_box_1">
                     <p>{{ $t("PC.About.1dhuqs") }}</p>
                     <span>{{ $t("PC.About.14qiag") }}</span>
                     <div class="article_line">
                        <div class="sliver"></div>
                        <div class="circle c_outside">
                           <div class="circle c_inside"></div>
                        </div>
                     </div>
                  </div>
                  <div class="content_box_2">
                     <p>{{ $t("PC.About.wre2me") }}</p>
                     <span>{{ $t("PC.About.v9fqry") }}</span>
                     <div class="article_line">
                        <div class="sliver"></div>
                        <div class="circle c_outside">
                           <div class="circle c_inside"></div>
                        </div>
                     </div>
                  </div>
                  <div class="content_box_3">
                     <p>{{ $t("PC.About.1otmo7") }}</p>
                     <span>{{ $t("PC.About.vhmzyl") }}</span>
                     <div class="article_line">
                        <div class="sliver"></div>
                        <div class="circle c_outside">
                           <div class="circle c_inside"></div>
                        </div>
                     </div>
                  </div>
                  <div class="content_box_4">
                     <p>{{ $t("PC.About.ldgmk8") }}</p>
                     <span>{{ $t("PC.About.hg71ro") }}</span>
                     <div class="article_line">
                        <div class="sliver"></div>
                        <div class="circle c_outside">
                           <div class="circle c_inside"></div>
                        </div>
                     </div>
                  </div>
                  <div class="content_box_5">
                     <p>{{ $t("PC.About.lhwvrp") }}</p>
                     <span>{{ $t("PC.About.qfj6p8") }}</span>
                     <div class="article_line">
                        <div class="sliver"></div>
                        <div class="circle c_outside">
                           <div class="circle c_inside"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </figure>
            <figure class="tab1_b3">
               <figcaption>{{ $t("PC.About.ci3twm") }}</figcaption>
               <div>
                  <a href="https://map.baidu.com/search/%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC/@13220466.035,3750660.43,19z?querytype=s&da_src=shareurl&wd=%E5%8D%97%E4%BA%AC%E5%B8%82%E9%BC%93%E6%A5%BC%E5%8C%BA%E5%8F%A4%E5%B9%B3%E5%B2%974%E5%8F%B7%E6%99%BA%E6%A2%A6%E5%9B%ADb%E5%BA%A75%E6%A5%BC&c=1&src=0&pn=0&sug=0&l=5&b=(2853289.960466571,1077844.7497895127;18581929.96046657,8753748.749789514)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1">
                     <img src="../../assets/zh/images/aboutus_img2.jpg" alt="" />
                  </a>
                  <article>
                     <p>{{ $t("PC.About.e905be") }}</p>
                     <p>{{ $t("PC.About.z5vowa") }}</p>
                     <p>{{ $t("PC.About.ey0807") }}</p>
                  </article>
               </div>
            </figure>
         </div>
      </section>
      <article class="tab1_bg1" ref="bg"></article>
      <article class="tab1_bg2"></article>
      <FooterC></FooterC>
   </section>
</section>
</template>

<script>
import FooterC from "./components/FooterC";

export default {
   components: {
      FooterC
   },
   data() {
      return {
         activeName: "first",
         banner: require('../../assets/zh/images/aboutus_banner@2x.jpg'),
         banner_en: require('../../assets/en/images/aboutus_banner@2x.jpg'),
      };
   },
   methods: {
      handleClick(tab, event) {
         setTimeout(() => {
            this.$refs.bg.style.height = `${tab.$el.offsetHeight+30}Px`
         }, 10)
      }
   }
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
   padding: 0 66Px;
}

.about {
   margin-top: 70Px;
   background-color: #fff;
   word-break: break-word !important;

   .banner {
      width: 100%;
      height: 440Px;
      position: relative;
      overflow: hidden;

      img {
         display: block;
         position: absolute;
         left: 50%;
         transform: translate(-50%, 0);
         width: 1920Px;
         height: 440Px;
         margin: 0 auto;
      }
   }

   .main_contain {
      position: absolute;
      top: 510Px;
      width: 100%;

      /deep/.el-tabs__content {
         z-index: 2;
      }

      &>article {
         width: 100%;
         background-color: #f5f6f9;
         position: absolute;
         z-index: -1;
      }

      .tab1_bg1 {
         top: 80Px;
         height: 518Px;
         z-index: 1;
         background-color: #f5f6f9;
      }

      .tab1_bg2 {
         height: 625Px;
         top: 1642Px;
      }

      .tab2_bg {
         // position: static;
         top: 80Px;
         height: 518Px;
         background: #f5f9fc;
      }

      section {
         background-color: #fff;

         .tab_1 {
            background-color: #f5f6f9;
            overflow: hidden;

            figure.tab1_b1 {
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin: 99Px 0;

               span {
                  width: 480Px;
                  height: auto;
                  font-size: 20Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  color: #333333;
                  line-height: 38Px;
               }
            }
         }

         .tab_2 {
            height: auto;
            display: flex;
            justify-content: space-between;

            div {
               width: 303Px;
               height: auto;
               padding: 20Px;
               box-sizing: border-box;
               word-break: break-all;
               margin-top: 85Px;
               background-image: url("../../assets/zh/images/aboutus_beijing.jpg");

               p {
                  height: auto;
                  font-size: 22Px;
                  color: #333;
                  line-height: 30Px;
                  text-align: center;
                  margin: 48Px 0 25Px;
               }

               span {
                  display: block;
                  height: auto;
                  font-size: 16Px;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 24Px;
                  letter-spacing: 1Px;

                  i {
                     white-space: nowrap;
                     font-family:   PingFangSC-Regular, PingFang SC;
                  }
               }
            }
         }

         .tab_3 {
            height: auto;
            display: flex;
            justify-content: space-between;

            &>p {
               height: auto;
               font-size: 32Px;
               font-family:   PingFangSC-Regular, PingFang SC;
               color: #333;
               line-height: 45Px;
               margin-top: 74Px;
               margin-left: 40Px;
            }

            &>div {
               width: 670Px;
               margin-top: 74Px;
               height: auto;
               border-left: 4Px solid #e8ecf4;
            }

            figure {
               font-size: 22Px;
               margin: 7Px 0 33Px 76Px;
               font-weight: 600;

               figcaption {
                  color: #003299;
                  margin-bottom: 12Px;
                  line-height: 30Px;
               }

               p {
                  color: #333;
                  font-family:   PingFangSC-Regular, PingFang SC;
                  line-height: 30Px;
                  font-weight: 500;
               }
            }
         }

         .tab_4 {
            height: auto;
            display: flex;
            justify-content: center;

            div {
               box-sizing: border-box;
               width: 361Px;
               height: auto;
               background: #eaf0f8;
               padding: 30Px 20Px 20Px 20Px;
               overflow: hidden;
               margin: 78Px 15Px;

               img {
                  margin: 0 auto;
               }

               figure {
                  text-align: center;

                  figcaption {
                     margin: 31Px 0 17Px;

                     span {
                        height: auto;
                        font-size: 22Px;
                        color: #003299;
                        font-weight: 600;
                        line-height: 25Px;
                     }

                     i {
                        display: inline-block;
                        vertical-align: bottom;
                        height: auto;
                        font-size: 14Px;
                        font-family:   PingFangSC-Regular, PingFang SC;
                        color: #333;
                        line-height: 17Px;
                     }
                  }

                  h1 {
                     display: block;
                     height: auto;
                     font-size: 16Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     color: #666;
                     font-weight: 400;
                     line-height: 22Px;
                     margin: 10Px 0;
                  }
               }
            }
         }
      }

      .xx {
         width: 100%;
         margin: 0 auto;
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;

         figure.tab1_b2 {
            width: 960Px;
            background-color: #fff;
            height: 1044Px;

            figcaption {
               padding: 70Px 0 37Px;
               text-align: center;
               font-size: 32Px;
               font-weight: 600;
               color: #3a3a3a;
               line-height: 45Px;
            }

            .b2_box {
               position: relative;

               article.center_b {
                  position: absolute;
                  top: 80Px;
                  left: calc(50% - 2Px);
                  width: 4Px;
                  height: 730Px;
                  background: #e8ecf4;
               }

               &>div {
                  width: 386Px;
                  height: auto;
                  background: #f5f9fc;
                  border-radius: 6Px;
                  position: absolute;
                  padding: 32Px 38Px 34Px 32Px;
                  box-sizing: border-box;
                  top: 300Px;

                  .article_line {
                     position: absolute;
                     width: 105Px;
                     height: 20Px;
                     display: flex;
                     align-items: center;
                     transform-origin: center center;
                     top: calc(50% - 12.5Px);

                     .sliver {
                        width: 85Px;
                        height: 4Px;
                        background-color: #003299;
                     }

                     .circle {
                        width: 20Px;
                        height: 20Px;
                        background-color: #003299;
                        border-radius: 50%;
                        position: relative;
                        z-index: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .c_inside {
                           width: 10Px;
                           height: 10Px;
                           background: #ffffff;
                        }
                     }
                  }

                  p {
                     margin-bottom: 14Px;
                     height: 33Px;
                     font-size: 24Px;
                     font-family:   PingFangSC-Medium, PingFang SC;
                     font-weight: 500;
                     color: #003299;
                     line-height: 33Px;
                  }

                  span {
                     height: 66Px;
                     font-size: 16Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     font-weight: 400;
                     color: #333333;
                     line-height: 22Px;
                  }
               }

               .content_box_1 {
                  top: 0;
                  left: 0;

                  .article_line {
                     right: -104Px;
                  }
               }

               .content_box_2 {
                  top: 160Px;
                  right: 0;

                  .article_line {
                     left: -104Px;
                     transform: rotate(180deg);
                  }
               }

               .content_box_3 {
                  top: 320Px;
                  left: 0;

                  .article_line {
                     right: -104Px;
                  }
               }

               .content_box_4 {
                  top: 480Px;
                  right: 0;

                  .article_line {
                     left: -105Px;
                     transform: rotate(180deg);
                  }
               }

               .content_box_5 {
                  top: 640Px;
                  left: 0;

                  span {
                     width: 316Px;
                  }

                  img {
                     right: -105Px;
                     transform: rotate(180deg);
                  }

                  .article_line {
                     right: -104Px;
                  }
               }
            }
         }

         figure.tab1_b3 {
            background: #f5f6f9;
            width: 100%;
            height: 625Px;

            figcaption {
               height: 45Px;
               font-size: 32Px;
               font-weight: 600;
               color: #3a3a3a;
               line-height: 45Px;

               margin: 70Px 0 40Px;
               text-align: center;
            }

            div {
               display: flex;
               justify-content: center;

               a {
                  &:hover {
                     cursor: pointer;
                  }
               }

               article {
                  width: 480Px;
                  background: #282a35;
                  padding: 105Px 0 0 50Px;

                  p {
                     height: auto;
                     font-size: 16Px;
                     color: #ffffff;
                     line-height: 22Px;
                     margin: 15Px 0;
                     padding-right: 45Px;
                  }
               }
            }
         }
      }
   }
}
</style>
